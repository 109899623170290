<template>
  <!-- 入力/確認フォームの親（データ保持用） -->
  <div>
    <p v-if="documentErrorMsg" class="text-caccent">{{ documentErrorMsg }}</p>
    <p v-if="receptionErrorMsg" class="text-caccent">{{ receptionErrorMsg }}</p>
    <router-view
      v-bind:stored-data="storedData"
      v-bind:documents-a="documentsA"
      v-bind:documents-b="documentsB"
      v-bind:reception-type-list="receptionTypeList"
      v-on:to-confirm="dataSave"
    ></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storedData: {
        // 入力データ保持用 入力確認クリック時に受け取る
        personalInfo: {
          surname: null,
          firstname: null,
          surnameKana: null,
          firstnameKana: null,
          dateOfBirth: null,
          gender: null,
          genderText: null,
          address1: null,
          address2: null,
          address3: null,
          extraAddress1: null,
          extraAddress2: null,
          extraAddress3: null,
          phoneNumber: null,
          email: null,
          receptionType: null,
          receptionTypeName: null,
        },
        extraAddressRequired: false,
        numberOfDocuments: null,
        identification: {
          document1: null,
          document2: null,
          file11: null,
          fileName11: null,
          file12: null,
          fileName12: null,
          file21: null,
          fileName21: null,
          file22: null,
          fileName22: null,
          faceImage: null,
        },
        documentUpload: {
          required11: false,
          required12: false,
          required21: false,
          required22: false,
          message11: null,
          message12: null,
          message21: null,
          message22: null,
        },
      },
      documentsA: [], //1種類の書類リスト
      documentsB: [], //2種類の書類リスト
      receptionTypeList: [], //コイン受け取り方法リスト
      documentErrorMsg: null,
      receptionErrorMsg: null,
    }
  },
  created() {
    this.getListItems()
  },
  methods: {
    getListItems() {
      // リスト選択項目取得 ＊本人性確認書類, コイン受取方法
      console.log('* get document_type *')
      this.documentErrorMsg = null
      this.receptionErrorMsg = null
      this.$axios
        .get('/account_opening/document_type')
        .then((response) => {
          console.log(response)
          this.documentsA = response.data.document1List
          this.documentsB = response.data.document2List
        })
        .catch((error) => {
          console.log(error)
          this.documentErrorMsg =
            'エラー : 本人性確認書類リストの取得に失敗しました。管理者にご連絡ください。'
        })
      console.log('* get reception_type *')
      this.$axios
        .get('/account_opening/reception_type')
        .then((response) => {
          console.log(response)
          this.receptionTypeList = response.data.receptionTypeList
        })
        .catch((error) => {
          console.log(error)
          this.receptionErrorMsg =
            'エラー : コイン受け取り方法リストの取得に失敗しました。管理者にご連絡ください。'
        })
    },

    dataSave(
      personalInfo,
      extraAddressRequired,
      numberOfDocuments,
      identification,
      documentUpload
    ) {
      console.log(
        '**** return',
        personalInfo,
        extraAddressRequired,
        numberOfDocuments,
        identification,
        documentUpload
      )
      Object.assign(this.storedData.personalInfo, personalInfo)
      this.storedData.extraAddressRequired = extraAddressRequired
      this.storedData.numberOfDocuments = numberOfDocuments
      Object.assign(this.storedData.identification, identification)
      Object.assign(this.storedData.documentUpload, documentUpload)
    },
  },
}
</script>
